import './AppInterface.css';

import { useEffect, useContext, useState } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { Alert } from '@mui/material'; 
import { AppContext } from 'components/AppContext';
import RoutesInterface from './RoutesInterface';
import DialogLoading from 'components/dialogs/DialogLoading';
//Material UI
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

// const welcome_message = "Welcome to PixelsAI, ask me Pixels releated questions"


function AppInterface() {

  const { global_app, updateGlobalVariables } = useContext(AppContext);
  const [isDialogLoadingOpen, setIsDialogLoadingOpen] = useState(false);
  const ALERT_TIMEOUT = 5000;
  useEffect(() => {
    //Managing App Level Alerts

    setTimeout(() => {
      let assign_alert = {
          show: false,
          severity: "error",
          message: "-"
          };
      if (global_app?.control?.alerts) {
          global_app.control.alerts.global_alert = assign_alert;
          }
      updateGlobalVariables(global_app);
      }, ALERT_TIMEOUT);
  }, [global_app?.control.alerts?.global_alert?.show]);

  return (

    <div className="AppInterface">
      <div className={true ? "AlertWrapper" : "AlertWrapperInactive"}>
      <Box>
        <Fade in={global_app?.control.alerts?.global_alert?.show}>
          <Alert severity={global_app?.control.alerts?.global_alert?.severity}>
              <p>
                {global_app?.control.alerts?.global_alert?.message}
              </p>
          </Alert >
        </Fade>
      </Box>
      </div>
      {global_app?.control.loadingPage ?
          <DialogLoading
              isVisible={true}
              setIsVisible={setIsDialogLoadingOpen}
              dialog_text="Loading..."
          />
      :
        null
      }
      <RoutesInterface />
    </div>
  );
}

export default AppInterface;
