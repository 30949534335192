
import React from 'react';
import { AppContext } from './AppContext';

export const AppContextProvider = ({ children }) => {

  const [global_app, setGlobalApp] = React.useState({
    global_app: {
      // Active User Profile
      profile: {
        username: undefined, 
        tokens: 0,
        logged: false, // Handle Login Session
        wallet_ronin: null, // User connected Wallet
      },
      // SideBar Status
      sideBarStatus: true,
      // Control Status
      control: {
        alerts: {
          global_alert: {
            message: "Error!",
            severity:"error",
            show: false
          }
        },
        loadingPage: true,
        register_data: {
          email_sent: false
        },
        bot_interface: {
          sending_message: false,
        },
        fetch_global_data: {
          profile: true
        },
        agent_server: {
          connect: false
        }
      },
      // Connections
      connections: {
        pixels_agent: {
          connected: true,
          send_message: false,
          message: null
        }
      },
      //Components Context: 
      // Login
      login: {
        loading: false
      }
    }
  });

  const updateGlobalVariables = newVariables => {
    setGlobalApp(prevVariables => ({
      ...prevVariables,
      ...newVariables,
    }));
  };

  return (
    <AppContext.Provider value={
      { ...global_app, updateGlobalVariables }}>
      {children}
    </AppContext.Provider>
  );
};