import React, { useContext, useEffect } from 'react';
import { getProfile } from '../../api';
import { AppContext } from '../../components/AppContext';

const FetchProfile = () => {
  const { global_app, updateGlobalVariables } = useContext(AppContext);

  useEffect(() => {
    const fetchProfileData = async () => {
        try {
            if (global_app.control.fetch_global_data.profile == true) {

                global_app.control.loadingPage = true;
                updateGlobalVariables(global_app);

                let res = await getProfile();
                let profile = await res.json();
                console.log()
                console.log(" JOJOJO _______ -------------")
                console.log(profile)
                if (res.status === 200) {
                    global_app.profile = profile;
                } else {
                    // Handle logout logic if needed
                    // let res_logout = await logout_api();
                }
            }
        }
        catch (error) {
            console.error('Error fetching profile:', error);
            // Handle error if needed
        } finally {
            global_app.control.loadingPage = false; // End loading
            global_app.control.fetch_global_data.profile  = false //Closing Fetch Profile flow
            global_app.control.loadingPage = false;
            updateGlobalVariables(global_app);
        }
    
    };

    // Only run the fetchProfileData when global_app.control.fetch_global_data.profile changes
    if (global_app.control.fetch_global_data.profile) {
      fetchProfileData();
    }
  }, [global_app.control.fetch_global_data.profile]); // Dependency array to trigger effect

  return null; // No UI to render, just side effects
};

export default FetchProfile;