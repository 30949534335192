import React, { useState, useEffect, useContext } from 'react';
import useWebSocket, { ReadyState } from "react-use-websocket"
import { AppContext } from "./AppContext";

const default_logout_profile =  {
    username: undefined, 
    tokens: 0,
    logged: false, // Handle Login Session
    wallet_ronin: null, // User connected Wallet
  }

const RECONNECT_ATTEMPTS = 3
export const PixelsAgent = ( { setMessages, setPerformingRequest, inputRef}) => {
    
    const PIXELS_AGENT_CONFIG = {
        socket_max_recconections: 3
    }
    const WS_HOST = process.env.REACT_APP_NAKAMABOT_WS_AGENT_URL
    const { global_app, updateGlobalVariables } = useContext(AppContext);
    const [reconnectAttempts, setReconnectAttempts] = useState(1);

    const { sendMessage, lastMessage, readyState } = useWebSocket(
        WS_HOST,
        {
            share: true,
            shouldReconnect: (closeEvent) => {
                return true; // Always attempt to reconnect
            },
            reconnectAttempts: 3, // Maximum number of reconnection attempts
            reconnectInterval: 3000, // Time between reconnection attempts, in milliseconds
        });

    // Pixels Agent Connection Status
    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            console.log("Connected to Nakama.bot Server.")
        }

        if (readyState === ReadyState.CLOSING) {
            console.log("Disconnecting from Nakama.bot Server.....")
        }

        if (readyState === ReadyState.CLOSED) {
            console.log("Disconnected from Nakama.bot Server..")
        }

        if (readyState === ReadyState.CONNECTING) {
            console.log("Connecting to Nakama.bot Server.....")
        }

    }, [readyState])

    // Send a new Message when Send_message Status changes to true
    useEffect(() => {
        if(global_app?.connections?.pixels_agent?.send_message == true){
            setPerformingRequest(true)
            console.log("Sending Message to Nakama.bot Agent Server.....")
            const json_message = {
                "message" : global_app?.connections.pixels_agent.message
            }

            sendMessage(json_message.message);
            
            setMessages(prevMessages => [
                ...prevMessages,
                {
                    text: json_message.message,
                    isBot: false
                },
                {
                    text: "Thinking...",
                    isBot: true
                }
                ]);
            console.log("Message Sent to PixelsAI Agent Server.")
        }

        global_app.connections.pixels_agent.send_message = false;
        
        console.log()
        console.log(" __________ HERE 2 _____________")
        console.log(global_app)
        updateGlobalVariables(global_app)
        

    }, [global_app?.connections.pixels_agent.send_message])

    useEffect(() => {
        let res_message = ""
        if(lastMessage && lastMessage.data){
            try {
                let res_message_obj = ""
                try {
                    const res_message_wrap = JSON.parse(lastMessage.data);
                    res_message_obj = res_message_wrap
                    try{
                        res_message_obj = JSON.parse(res_message_wrap)
                    } catch (error) {
                        res_message_obj = res_message_wrap
                    }
                } catch (error) {}
                // console.log("Response Message: ", res_message_obj)
                // Socket Auth Logic
                if(res_message_obj["status"] == "error"){
                    setPerformingRequest(false)
                    throw new Error(res_message_obj["message"])
                } else if(res_message_obj["status"] == "warning"){
                    let assign_alert = {
                        show: true,
                        severity: "warning",
                        message: res_message_obj["message"]
                        }
            
                    if (global_app?.control?.alerts) {
                        global_app.control.alerts.global_alert = assign_alert;
                    }
                    if(res_message_obj["message"] == "Insufficient funds"){
                        setMessages(prevMessages => [
                            ...prevMessages.slice(0,-1),
                            {
                                text: ":(",
                                isBot: true
                            }
                            ]);
                        setPerformingRequest(false)
                    }
                    updateGlobalVariables(global_app)
                    return
                }

                console.log()
                console.log(" __________ HERE REMAINING TOKENS _____________")
                console.log(res_message_obj)
                // In case of remaining tokens, update them
                if("remaining_tokens" in res_message_obj){
                    global_app.profile.tokens = res_message_obj["remaining_tokens"]
                    updateGlobalVariables(global_app)
                }
                // Chat Logic
                res_message = res_message_obj["message"];
                setMessages(prevMessages => [
                    ...prevMessages.slice(0,-1),
                    {
                        text: res_message,
                        isBot: true
                    }
                    ]);
                setPerformingRequest(false)
                if(inputRef){
                    // console.log(inputRef)
                    inputRef.current.value = 2;
                    //TODO: Fix Focusing after send message
                    // console.log("FOCUSING >>>>")
                    inputRef.current.focus();
                    inputRef.current.select();
                }

            } catch (error) {

                setReconnectAttempts(reconnectAttempts + 1)


                console.log("Error calling PixelsAI Server: ", error)
                console.log("try: ", reconnectAttempts)
                
                if(reconnectAttempts == PIXELS_AGENT_CONFIG.socket_max_recconections){
                    global_app.profile = default_logout_profile
                    let assign_alert = {
                        show: true,
                        severity: "error",
                        message: "Session Expired! Login Again "
                        }
            
                    if (global_app?.control?.alerts) {
                        global_app.control.alerts.global_alert = assign_alert;
                    }
                    updateGlobalVariables(global_app)

                }
                return
            }
        }

    }, [lastMessage])

  return 
}