import React from 'react';
import "./RoninWalletConnection.css"
import { useState, useEffect, useContext } from 'react';
// App Context
import { AppContext } from 'components/AppContext';
import DialogLoading from 'components/dialogs/DialogLoading';
import DialogBox from 'components/dialogs/DialogBox';

// Assets
import roninLogo from 'images/ronin-logo.svg';

// Apis
import { login_api, logout_api } from 'api';

// General Utils
import { isMobile } from 'utils/mobile_utils';
// Web3 - Ronin
import { WalletSDK } from '@roninnetwork/wallet-sdk'
import { SiweMessage } from "siwe";
import { Buffer } from 'buffer';
// Web3 Utils
import {formatString} from 'utils/web3/wallet_utils'






const RoninWalletConnection = () => {

    const { global_app, updateGlobalVariables } = useContext(AppContext);
    const [userAddress, setUserAddress] = useState()
    const [uri, setUri] = useState();
    const [isDialogLoadingOpen, setIsDialogLoadingOpen] = useState(false);
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false);

    const checkRoninInstalled = () => {
        if ('ronin' in window) {
            return true
        } 
        
        //window.open('https://wallet.roninchain.com', '_blank') 
        console.log('Ronin Wallet is not Installed.')
        return false
    }

    async function connectRoninWallet(event) {
        setIsDialogLoadingOpen(true)
        const sdk = new WalletSDK()
        if(isMobile()){
            if(!sdk) {
                alert('SDK is not ready')
              }
              
            // TODO: Feature: Mobile Wallet Connect
            //   sdk.on('display_uri', wcUri => {
            //     setUri(wcUri)
            //   })
            //   await sdk.connectMobile()
          
            //   const accounts = await sdk.getAccounts()
            //   if (accounts) {
            //     setUserAddress(accounts[0])
            //   } 

            setUri("Connect on Desktop")
        } else {
            // Connect on Desktop
            try {
                event.preventDefault();
                await sdk.connectInjected();
            
                const isInstalled = checkRoninInstalled()
                
                if (isInstalled === false) {
                    console.log("Ronin Wallet is not installed")
                    return;
                }
                const accounts = await sdk.requestAccounts()
                if (accounts) {
                    let account = accounts[0]
                    console.log("Selecting Account: "+account.toString())
                    setUserAddress(account)
                    
                    // Generating Firm to validate wallet
                    const siweMessage = new SiweMessage({
                        domain: window.location.hostname,
                        address: account,
                        uri: window.location.origin,
                        version: "1",
                        chainId: 2020,
                        nonce: "12345678",
                        statement:
                        "I accept the dApp's Terms of Service of https://Nakama.bot"
                    })
                    let sign_message = siweMessage.toMessage()

                    const signature = await sdk.request({ 
                        method: 'personal_sign',
                        params: [sign_message, account]
                    })
                    
                    sign_message = Buffer.from(sign_message).toString('base64');
                    const signature_address = await login_api(account, signature, sign_message)
                    if(signature_address){
                        global_app.control.fetch_global_data.profile = true
                        global_app.profile.connected_ronin_wallet = account
                        global_app.control.agent_server.connect = true
                        updateGlobalVariables(global_app)
                    }
                    // console.log("RESPONSE OF UPDATE WALLET: ", signature_address)

                    // console.log("THE ADDRESS IS : ", signature_address)
                }
                
            } catch (error) {
                console.log("Wallet rejected to connect");
                setUserAddress(null);
            }
        }
        setIsDialogLoadingOpen(false) 
    }

    // Dialog Box Logic
    const handleOpenDialog = () => setIsDialogBoxOpen(true);
    const handleCloseDialog = () => setIsDialogBoxOpen(false);

     // Disconnect Wallet Method
     const disconnectWallet = async() => {
        console.log("Disconnecting Wallet...");
        setIsDialogBoxOpen(false);
        setIsDialogLoadingOpen(true);
        try{
            let res_logout = await logout_api();
            let data_logout = await res_logout.json();
            global_app.profile.logged = false;
            await updateGlobalVariables(global_app);
        }
        catch{
            console.log("ERROR >>>>>> Logout")
        }
        global_app.profile.connected_ronin_wallet = null
        await updateGlobalVariables(global_app)
        setIsDialogLoadingOpen(false)
    };

    return (
        <div className="ronnin-wallet-connection-wrapper">
            <DialogLoading
                isVisible={isDialogLoadingOpen}
                setIsVisible={setIsDialogLoadingOpen}
                dialog_text="Loading..."
            />
            <DialogBox
                isOpen={isDialogBoxOpen}
                onCancel={handleCloseDialog}
                onOption={disconnectWallet}
                dialog_text="Do you want to disconnect your wallet?"
                option_name="Disconnect"
            />
            
                {global_app?.profile?.connected_ronin_wallet && 
                    // Case: Wallet already connected to profile
                    <button className="login-button-ronin" onClick={handleOpenDialog} >
                        <div className="connect-ronin-wallet-button-wrapper">
                            <img src={roninLogo} alt="Login With Ronin"/>
                            <span>{formatString(global_app.profile.connected_ronin_wallet)}</span>
                        </div>
                    </button>
                }
                {!global_app?.profile?.connected_ronin_wallet  && isMobile() && 
                    // Case: Wallet already connected to profile
                    <button className="login-button-ronin" disabled={true}>
                        <img src={roninLogo} alt="Login With Ronin"/>
                        <span>Connect Wallet on Desktop</span>
                    </button>
                }

                
                {!global_app?.profile?.connected_ronin_wallet  && !isMobile() && 
                    // Case: Connect Wallet on Desktop
                    <button className="login-button-ronin" onClick={(e) => connectRoninWallet(e)}>
                        <div className="connect-ronin-wallet-button-wrapper">
                            <img src={roninLogo} alt="Login With Ronin"/>
                            <span>Connect Ronin Wallet</span>
                        </div>
                    </button>
                }




        </div>
    );
}

export default RoninWalletConnection;
