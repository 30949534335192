import './RoutesInterface.css';

import { useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


import { AppContext } from "components/AppContext";
import Bot from 'pages/Bot';
import Login from 'pages/Login';
import MCLanding from 'pages/MCLanding/MCLanding';
// const welcome_message = "Welcome to PixelsAI, ask me Pixels releated questions"

function RoutesInterface() {

//   // Global States
  function RedirectToHome() {
    const navigate = useNavigate();

    useEffect(() => {
      navigate('/app/');
    }, [navigate]);
  
    return null;
  }

  const { global_app } = useContext(AppContext);
  return (

    <div className="AppWrapper">
      {/* {alert != undefined && <Alert severity="success"> {alert} </Alert>} */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1"></meta>
      <title>PixelsAI</title>
      <link rel="canonical" href="http://mysite.com/example" />
      <Router>
        <Routes>

          {/* App Routes */}
          {<Route path="/app/" element={<Bot />} />} 
          {<Route path="*" element={<RedirectToHome />} /> }
          
        </Routes>
      </Router>
    </div>
  );
}

export default RoutesInterface;
