// DialogBox.js
import React from 'react';
import './DialogLoading.css'; // Importing the CSS for styling
import LoadingAnimation from 'components/LoadingAnimation';
import { useState, useEffect } from 'react';

const DialogLoading = ({ isVisible, setIsVisible }) => {
  const handleClose = () => {
    setIsVisible(false); 
  };

  return isVisible ? (
    <div className="dialog-overlay" onClick={handleClose}>
      <div className="dialog-loading">
        <div className="dialog-content-loading">
          <div className="loading-section">
            <LoadingAnimation />
          </div>
          <div className="dialog-text-section">
            <p>Processing...</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default DialogLoading;