// DialogBox.js
import React from 'react';
import './DialogBox.css'; // Importing the CSS for styling

const DialogBox = ({ isOpen, onCancel, onOption, dialog_text, option_name }) => {
  if (!isOpen) return null; // Only render the dialog if isOpen is true

  return (
    <div className="dialog-overlay">
      <div className="dialog">
        <div className="dialog-content dialog-content-confirm ">
          <div className="dialog-text-section">
            <p>{dialog_text}</p>
          </div>
          <div className="buttons-section">
            <button onClick={onCancel}>Cancel</button>
            <button className="disconnect-button" onClick={onOption} >{option_name}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogBox;