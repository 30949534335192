import React, { useRef } from 'react';
import '../App.css'
import appLogo from '../images/logo.png';
import addBtn from '../images/assets/add-30.png';
import msgIcon from '../images/assets/message.svg';

import homeImg from '../images/assets/home.svg';
import savedImg from '../images/assets/bookmark.svg';
import rocketImg from '../images/assets/rocket.svg';

import Markdown from 'marked-react';

import sendBtnImage from '../images/assets/send.svg';
import userIconImg from '../images/assets/user-icon.png';
import pixelsChatLogo from '../images/assets/pixelsChatLogo.png';

import { askApi, getProfile, logout_api, updateProfileWallet, disconnectProfileWallet, ConfirmTxHash } from '../api';
import { useState, useEffect } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../components/AppContext';
import { useContext } from 'react';
import Cookies from 'js-cookie';
import {QRCodeCanvas} from 'qrcode.react';

import DialogLoading from 'components/dialogs/DialogLoading';
import DialogBox from 'components/dialogs/DialogBox';
import DialogConfirmTx from 'components/dialogs/DialogConfirmTx';
import roninLogo from '../images/ronin-logo.svg';
import {PixelsAgent} from 'components/SocketAgent';
import FetchProfile from 'components/utils/FetchProfile'

// Web3 - Ronin
import { WalletSDK } from '@roninnetwork/wallet-sdk'
import { SiweMessage } from "siwe";
import { Buffer } from 'buffer';
// Web3 utils
import {formatString} from 'utils/web3/wallet_utils'
//Internal Components
import RoninWalletConnection from 'components/connections/RoninWalletConnection';
import loading_animation from '../images/assets/loading-animation-1.gif';

//Icons
import FeatherIcon from 'feather-icons-react';

const welcome_message = "Welcome to Nakama.bot, ask me Pixels releated questions"

const Bot = () => {

    const [ input, setInput ] = useState("");
    const inputRef = useRef(null);
    const [ performingRequest, setPerformingRequest ] = useState(false);
    const { global_app, updateGlobalVariables } = useContext(AppContext);
    const [uri, setUri] = useState()
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false);
    const [isDialogConfirmTxOpen, setIsDialogConfirmTxOpen] = useState(false);
    const [isDialogLoadingOpen, setIsDialogLoadingOpen] = useState(false);


    const [ messages, setMessages ] = useState([{
        text: welcome_message,
        isBot: true
    }]);

    // General States
    const [loading, setLoading] = useState(false);

    const chatbotRef = useRef(null);
    const chatsRef = useRef(null);
    
    // Wallet Connect States
    const [userAddress, setUserAddress] = useState()

    // Auth
    // const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const isMobile = () => {
        // Patterns to check in the userAgent string
        const mobilePatterns = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        return mobilePatterns.test(navigator.userAgent);
    };

    // Calling Profile 1 time one page is rendered
    // useEffect(() => {

    //     const fetchProfile = async () => {
    //         try {
    //             let res = await getProfile();
    //             let profile = await res.json();

    //             if(res.status == 200){
    //                 console.log("THE PROFILE: ", profile)
    //                 updateGlobalVariables(global_app.profile = profile);
    //                 updateGlobalVariables(global_app.control.loadingPage = false);
    //             }
    //         } catch (error) {
    //             console.error("Error fetching profile:", error);
    //         }
    //     };
    
    //     fetchProfile();
    // }, []); // Run this effect only once when the component mounts

    // Ronin Wallet
    
    // #### RONIN CODE #####
    const checkRoninInstalled = () => {
        if ('ronin' in window) {
            return true
        } 
        
        //window.open('https://wallet.roninchain.com', '_blank') 
        console.log('Ronin Wallet is not Installed.')
        return false
    }

    // Dialog Box Logic
    const handleOpenDialog = () => setIsDialogBoxOpen(true);
    const handleCloseDialog = () => setIsDialogBoxOpen(false);

    // Dialog Confirm Tx Logic
    const handleOpenConfirmTxDialog = () => setIsDialogConfirmTxOpen(true);
    const handleCloseConfirmTxDialog = () => setIsDialogConfirmTxOpen(false);

    // Disconnect Wallet Method
    const disconnectWallet = async() => {
        console.log("Disconnecting Wallet...");
        setIsDialogBoxOpen(false); // Close the dialog after connecting
        setIsDialogLoadingOpen(true);
        const disconnect_result = await disconnectProfileWallet();
        global_app.profile.connected_ronin_wallet = null
        await updateGlobalVariables(global_app)
        setIsDialogLoadingOpen(false)
    };

    const confirming_claiming_transaction = async(tx_hash) => {
        setIsDialogConfirmTxOpen(false); // Close the dialog after connecting
        setIsDialogLoadingOpen(true);
        const confirm_tx_hash_response = await ConfirmTxHash(tx_hash);
        const confirm_tx_hash_response_data = await confirm_tx_hash_response.json();
        if(confirm_tx_hash_response){
            let confirm_tx_severity = "success"
            
            let new_token_balance = confirm_tx_hash_response_data['user_updated_token_amount']
            let new_balance_message = "New Tokens Claimed! New Balance: "+new_token_balance+" Tokens"

            if(confirm_tx_hash_response.status != 200) {
                console.log(confirm_tx_hash_response_data)
                confirm_tx_severity = "error"
                new_balance_message = confirm_tx_hash_response_data["detail"]
                
            } else {
                global_app.profile.tokens = new_token_balance
            }

            let assign_alert = {
                show: true,
                severity: confirm_tx_severity,
                message: new_balance_message
                }

            
            global_app.control.alerts.global_alert = assign_alert;
            
            updateGlobalVariables(global_app);

        }
        setIsDialogLoadingOpen(false);
    }

      
    async function connectRoninWallet(event) {
        setIsDialogLoadingOpen(true)
        const sdk = new WalletSDK()
        if(isMobile()){
            if(!sdk) {
                alert('SDK is not ready')
              }
              
            // TODO: Feature: Mobile Wallet Connect
            //   sdk.on('display_uri', wcUri => {
            //     setUri(wcUri)
            //   })
            //   await sdk.connectMobile()
          
            //   const accounts = await sdk.getAccounts()
            //   if (accounts) {
            //     setUserAddress(accounts[0])
            //   } 

            setUri("Connect on Desktop")
        } else {
            // Connect on Desktop
            try {
                event.preventDefault();
                await sdk.connectInjected();
            
                const isInstalled = checkRoninInstalled()
                if (isInstalled === false) {
                    console.log("Ronin Wallet is not installed")
                    return;
                }
                
                const accounts = await sdk.requestAccounts()
                
                if (accounts) {
                    let account = accounts[0]
                    console.log("Selecting Account: "+account.toString())
                    setUserAddress(account)
                    
                    // Generating Firm to validate wallet

                    const siweMessage = new SiweMessage({
                        domain: window.location.hostname,
                        address: account,
                        uri: window.location.origin,
                        version: "1",
                        chainId: 2020,
                        nonce: "12345678",
                        statement:
                        "I accept the dApp's Terms of Service of https://pixelsai.app"
                    })
                    let sign_message = siweMessage.toMessage()

                    const signature = await sdk.request({ 
                        method: 'personal_sign',
                        params: [sign_message, account]
                    })

                    console.log("THE SIGNATURE IS:")
                    console.log(signature)

                    console.log("NOW CHECKING SIGNATURE ... ")
                    sign_message = Buffer.from(sign_message).toString('base64');
                    const signature_address = await updateProfileWallet(account, signature, sign_message)
                    if(signature_address){
                        global_app.profile.connected_ronin_wallet = account
                        updateGlobalVariables(global_app)
                    }
                    // console.log("RESPONSE OF UPDATE WALLET: ", signature_address)

                    // console.log("THE ADDRESS IS : ", signature_address)
                }
            } catch (error) {
                console.log("Wallet rejected to connect");
                setUserAddress(null);
            }
        }
        setIsDialogLoadingOpen(false) 
    }
   
          
    useEffect(() => {
        if (chatsRef && chatsRef.current) {
            chatsRef.current.scrollTop = chatsRef.current.scrollHeight;
        }
    }, [messages]);


    const changeSideBarState = () => {
        if(global_app?.sideBarStatus){
            window.scrollTo(0, 0); // Moving the User to the tom with the menu //TODO: Check if works
            updateGlobalVariables( global_app.sideBarStatus = false)
        } else {
            updateGlobalVariables( global_app.sideBarStatus = true)
        }
    }
    const handleSend = async () => {
        //Ignoring when field is empty
        if(input === "" || input == undefined || input == null) return;
        // Blocking inputs until request finish
        setPerformingRequest(true);
        // Adding First User Message
        // setMessages(prevMessages => [
        // ...prevMessages,
        // {
        //     text: input,
        //     isBot: false
        // },
        // {
        //     text: "Thinking...",
        //     isBot: true
        // }
        // ]);

        // const res = await  askApi(input)
        // //CallGetProfile

        // if(res?.remaining_tokens){
        //     global_app.profile.tokens = res.remaining_tokens
        //     updateGlobalVariables(global_app)
        // }
        // console.log("THE RES: ", res)
        // //var test = messages.filter(msg => !(msg.text === "Thinking..." && msg.isBot === true))
        // //console.log(test)
        // setMessages(prevMessages => [
        // ...prevMessages.slice(0,-1),
        // {
        //     text: res.message,
        //     isBot: true
        // }
        // ]);

        
        // Unblocking inputs
        setPerformingRequest(false);

        // Test
        global_app.connections.pixels_agent.send_message = true;
        global_app.connections.pixels_agent.message = input;
        updateGlobalVariables(global_app)


    };
    
    return (
        <div className={global_app?.sideBarStatus ? "App flexColumnDirectionPhone" : "App"}>
            <DialogBox
                isOpen={isDialogBoxOpen}
                onCancel={handleCloseDialog}
                onOption={disconnectWallet}
                dialog_text="Are you sure you want to disconnect your wallet?"
                option_name="Disconnect"
            />
            <DialogConfirmTx
                isOpen={isDialogConfirmTxOpen}
                onCancel={handleCloseConfirmTxDialog}
                onConfirm={confirming_claiming_transaction}
            />
             
            <div className={global_app?.sideBarStatus ? "sideBar" : "sideBarHidden"} >
                <div className="upperSide">
                    <div className="upperSideTop">
                        <img src={appLogo} alt="PixelsAI" className="logo"/>
                        <span className="logoText">Nakama.bot</span>
                    </div>
                    <div className="menu-button-wrapper">
                        {global_app.profile.connected_ronin_wallet ? <button className="menu-panel-half"><FeatherIcon icon="package" className="listItemsIcon" fill="#02071C" size="32"/>tokens: {global_app.profile?.tokens}</button> : null}
                        <RoninWalletConnection/>
                    </div>
                    {/* <button className="menu-button-wrapper">Username: {global_app?.profile?.username}</button> */}
                    <div className="upperSideBottom">
                        <button className="menu-panel"><FeatherIcon icon="package" className="listItemsIcon" fill="#02071C" size="32"/>tokens: {global_app.profile?.tokens}</button>
                        <button className="menu-panel"><FeatherIcon icon="help-circle" className="listItemsIcon" fill="#02071C" size="32"/> How to use PixelsAI?</button>
                    </div>
                </div>

                <div className="lowerSide">
                    {/* <div className="listItems">
                        <button onClick={ (e) => logout(e)}>
                        <FeatherIcon icon="log-out" className="listItemsIcon" fill="#02071C" size="32"/>
                        Logout
                        </button>
                    </div> */}
                    {/* <div className="listItems">
                        <img src={savedImg} alt="Saved" className="listItemsImg"/>
                        Saved
                    </div> */}

                    {/* <div className="listItems">
                        <button disabled={false} onClick={handleOpenConfirmTxDialog}>
                        <FeatherIcon icon="package" className="listItemsIcon" fill="#02071C" size="32"/>
                        Get Tokens
                        </button>
                    </div>
                    <div className="listItems">
                    
                        {!loading && global_app?.profile?.connected_ronin_wallet && 
                        // Case: Wallet already connected to profile
                            <button className="login-button-ronin" onClick={handleOpenDialog} >
                                <span>{formatString(global_app.profile.connected_ronin_wallet)}</span>
                            </button>
                        }
                    
                        {!loading && !global_app?.profile?.connected_ronin_wallet  && isMobile() && 
                        // Case: Wallet already connected to profile
                            <button className="login-button-ronin" disabled={true}>
                                <span>Connect Wallet on Desktop</span>
                            </button>
                        }

                        
                        {!loading && !global_app?.profile?.connected_ronin_wallet  && !isMobile() && 
                        // Case: Connect Wallet on Desktop
                            <button className="login-button-ronin" onClick={(e) => connectRoninWallet(e)}>
                                <span>Connect Ronin Wallet</span>
                            </button>
                        }

                    </div> */}
                </div>

            </div>
            
            <div className={!global_app?.sideBarStatus ? "sidebarButtonWrapper" : "sidebarButtonWrapperOpen"}>
                <button onClick={changeSideBarState}>
                    {global_app?.sideBarStatus ? 
                        <FeatherIcon icon="more-horizontal" className="menuExpandButton" fill="#02071C" size="35"/>
                    :
                        <FeatherIcon icon="more-vertical" className="menuExpandButton" fill="#02071C" size="35"/>
                    }
                </button>
            </div>
            
            {global_app.control.agent_server.connect ?
                <PixelsAgent
                    setMessages={setMessages}
                    setPerformingRequest={setPerformingRequest}
                    inputRef={inputRef}
                />
                :
                null
            }

            {/* {global_app.profile.whitelist ? ( */}
            {true ? (
                <div>
                    <div className="chatbotPage">
                        <div className="chatBotContainer">
                            <div className="chatbot" ref={chatbotRef}>
                                <div className="chats" ref={chatsRef}>
                                    {
                                        messages.map((msg, index) =>
                                        <div className={msg.isBot ? "chatWrapperBot" : "chatWrapper"} key={index}>
                                            <div className={msg.isBot ? "chatMessageBot" : "chatMessage"}>
                                                <img src={msg.isBot ? pixelsChatLogo : userIconImg} alt="" className="chatImg" id={index} />
                                                <p className="chatText">
                                                    {msg.text === "Thinking..." ? (
                                                        <span>
                                                            {msg.text} 
                                                            <img className="msgLoadingImg" src={loading_animation} alt="Loading..." />
                                                        </span>
                                                        ) : (
                                                        <span><Markdown>{msg.text}</Markdown> </span>
                                                    )}
                                                </p>
                                                
                                            </div>
                                        </div>
                                        )
                                    }
                                </div>
                                <div className="chatFooter">
                                    <div className="inp">
                                        <input 
                                            ref={inputRef} 
                                            type="text" 
                                            placeholder="Type a message" 
                                            className="msgInp" value={input} 
                                            onChange={(e)=>setInput(e.target.value)}
                                            disabled={performingRequest}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setInput("");
                                                    handleSend();
                                                    e.preventDefault(); // Prevents the addition of a new line in the input after pressing 'Enter'
                                                }
                                            }
                                            }
                                        />
                                        <button className="sendBtn" onClick={handleSend} disabled={performingRequest}>
                                            <img src={sendBtnImage} alt="Send" className="sendBtnImg"/>
                                        </button>
                                    </div>
                                    <p className="chatDisclaimer"> PixelsAI might reach incorrect conclusions and suffer from the typical hallucinations of large language models. Developed by Manolocap.ron.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    No whitelist
                </div>
            )}
            
            <FetchProfile/>
        </div>
    );
};

export default Bot;