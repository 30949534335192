
import axios from 'axios';

var globalHeaders = new Headers();
globalHeaders.append("Content-Type", "application/x-www-form-urlencoded");

const API_PORT = process.env.API_PORT || "8444";
//const API_PORT_STRING = API_PORT
//const API_HOST = "https://api.nakama.bot/api/"
// const API_HOST = process.env.PIXELSAI_SERVER_URL || "http://localhost:"+API_PORT+"/api/"
const API_HOST = process.env.REACT_APP_NAKAMABOT_SERVER_URL
//const API_HOST = "https://f51b-2800-98-1a16-14d7-adfe-1bf3-8758-c990.ngrok-free.app/api/"
export async function askApi(prompt) {

    try {
        var myHeaders = {}
        myHeaders["Content-Type"] = "application/json";

        console.log("TYPE: ", prompt, typeof(prompt))
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({ prompt: prompt }),
            redirect: 'follow',
            credentials: 'include',
        };

        const res = await fetch(API_HOST+"ask", {
            ...requestOptions
        }).catch(error => console.log('error', error));

        var data = await res.json();
        console.log("RESPONSE DATA: ")
        console.log(data)
        return data
    }
    catch (error) {
        console.error("ERROR API ASK: ", error)
        return error
    }
}
export async function getProfile() {

    try {
        var myHeaders = {}
        myHeaders["Content-Type"] = "application/x-www-form-urlencoded";
        let urlencoded = new URLSearchParams();

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow',
            credentials: 'include'
        };
        console.log( "API HOST: ", API_HOST)
        const res = await fetch(API_HOST+"user/get-profile", {
            ...requestOptions
        });
        return res
    }
    catch (error) {

        return error
    }
}

export async function login_api(wallet, signature, message) {

    var data = {
        "message": "Logged in",
        "access_token" : null,
        'status': 'success'
    }

    try{

        const urlencoded = new URLSearchParams();
        urlencoded.append("wallet", wallet);
        urlencoded.append("signature", signature);
        urlencoded.append("message", message);
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: urlencoded.toString(),
            credentials: 'include',  // changed from 'include'
        };
        try {

            const response = await fetch(API_HOST+"login", requestOptions);

            return response;
        } catch (error) {
            return { status: 'error', message: 'LOGIN API ERROR' };
        }
        
    }
    catch (error){
        console.log("ERROR API: ", error)
        data.status = 'error'
        alert('API ERROR')
        return data
    }
    
}

export async function logout_api() {

    var data = {}

    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: null,
            credentials: 'include',  // changed from 'include'
        };

        const response = await fetch(API_HOST+"logout", requestOptions);
        return response;
    }

    catch (error){
        console.log("ERROR API LOGOUT: ", error)
        return { status: 'error', message: 'LOGOUT API ERROR' };
        
    }
    
}

export async function updateProfileWallet(wallet, signature, message) {

    try {
        var myHeaders = {}
        myHeaders["Content-Type"] = "application/json";
        var requestOptions = {
            method: 'POST',
            // headers: myHeaders,
            body: JSON.stringify({ signature: signature, message: message, wallet: wallet}),
            redirect: 'follow',
            credentials: 'include',
        };

        const res = await fetch(API_HOST+"user/profile/wallet/update", {
            ...requestOptions
        }).catch(error => console.log('error', error));

        var data = await res.json();
        console.log("RESPONSE DATA: ")
        console.log(data)
        return data
    }
    catch (error) {
        console.error("ERROR API Update Wallet: ", error)
        return error
    }
}

export async function disconnectProfileWallet() {

    try {
        var myHeaders = {}
        myHeaders["Content-Type"] = "application/json";

        var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            credentials: 'include',
        };

        const res = await fetch(API_HOST+"user/profile/wallet/disconnect", {
            ...requestOptions
        }).catch(error => console.log('error', error));

        var data = await res.json();
        console.log("RESPONSE DATA: ")
        console.log(data)
        return data
    }
    catch (error) {
        console.error("ERROR API Update Wallet: ", error)
        return error
    }
}

export async function ConfirmTxHash(tx_hash) {

    try {
        var myHeaders = {}
        myHeaders["Content-Type"] = "application/json";
        var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify({ tx_hash: tx_hash}),   
            credentials: 'include',
        };

        const res = await fetch(API_HOST+"user/profile/wallet/getTokensByTxHash", {
            ...requestOptions
        }).catch(error => console.log('error', error));

        return res
    }
    catch (error) {
        console.error("ERROR API Update Wallet: ", error)
        return error
    }
}

export async function ConfirmRegisterNewUser(user_data) {

    try {
        console.log("REGISTERING USER: ", user_data)
        var myHeaders = {}
        myHeaders["Content-Type"] = "application/json";

        var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify( user_data )
        };

        const res = await fetch(API_HOST+"confirm-register", {
            ...requestOptions
        }).catch(error => console.log('error', error));

        return res
    }
    catch (error) {
        console.error("ERROR Registering the user: ", error)
        return error
    }
}

export async function RegisterNewUser(user_data) {

    try {
        var myHeaders = {}
        myHeaders["Content-Type"] = "application/json";
        var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify( user_data )
        };

        const res = await fetch(API_HOST+"register", {
            ...requestOptions
        }).catch(error => console.log('error', error));

        return res
    }
    catch (error) {
        console.error("ERROR Registering the user: ", error)
        return error
    }
}