import './App.css';
import { askApi } from './api';
import { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { AppContextProvider } from './components/AppContextProvider';
import AppInterface from './pages/interfaces/AppInterface';

const version = "0.0.03";

function App() {

  function RedirectToLogin() {
    const navigate = useNavigate();
    useEffect(() => {
      navigate('/login');
    }, [navigate]);
  
    return null;
  }

  function RedirectToHome() {
    const navigate = useNavigate();
    useEffect(() => {
      navigate('/');
    }, [navigate]);
  
    return null;
  }


  return (

//     <AppContextProvider>
//       {/* {alert != undefined && <Alert severity="success"> {alert} </Alert>} */}
//       <meta charSet="utf-8" />
//       <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1"></meta>
//       <title>My Title</title>
//       <link rel="canonical" href="http://mysite.com/example" />
//       <Router>
//         <Routes>
// {/* 
//           {global_app?.profile?.access_token && <Route path="/" element={<Bot />} />}
//           {!global_app?.profile?.access_token && <Route exact path="/login" element={<Login />} />}
//           {!global_app?.profile?.access_token && <Route path="*" element={<RedirectToLogin />} /> }
//           {global_app?.profile?.access_token && <Route path="*" element={<RedirectToHome />} /> } */}

//           {true && <Route exact path="/login" element={<Login />} />}

//         </Routes>
//       </Router>
//     </AppContextProvider>

    <AppContextProvider>
      <AppInterface />
    </AppContextProvider>

  );
}

export default App;
