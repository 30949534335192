import React from 'react';
import "./LoadingAnimation.css";
import loadingImage from '../images/assets/loading.svg'; // Replace with the actual path to your image

class LoadingAnimation extends React.Component {
    render() {
        return (
            <div>
                <img src={loadingImage} alt="Loading Animation" />
            </div>
        );
    }
}


export default LoadingAnimation;